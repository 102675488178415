<template>
  <div class="dialog-box">
    <VhallDialog
      :title="advInfo.adv_id ? '编辑广告' : '创建广告'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="advinfo-dialog"
      width="468px"
    >
      <vh-form label-width="80px" :model="advertisement" ref="advertisementForm" :rules="rules">
        <vh-form-item label="推广图片" prop="img_url">
          <div class="img-box">
            <upload
              class="giftUpload"
              ref="advise_uploader"
              :heightImg="100"
              v-model="advertisement.img_url"
              :domain_url="domain_url"
              :saveData="saveImageData"
              :on-success="uploadAdvSuccess"
              :on-change="handleUploadChange"
              @delete="deleteImg"
              :auto-upload="false"
            >
              <div slot="tip">
                <p>建议尺寸：400*225px，小于2M</p>
                <p>支持jpg、gif、png、bmp</p>
              </div>
            </upload>
          </div>
        </vh-form-item>
        <vh-form-item label="标题" prop="subject">
          <vh-input
            v-model="advertisement.subject"
            :maxlength="15"
            show-word-limit
            placeholder="请输入广告标题"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="链接" prop="url">
          <vh-input
            v-model="advertisement.url"
            placeholder="请输入以http://或https://开头广告链接"
            autocomplete="off"
          ></vh-input>
        </vh-form-item>
      </vh-form>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button type="info" size="medium" @click="dialogVisible = false" plain round>
          取消
        </vh-button>
        <vh-button
          type="primary"
          size="medium"
          v-preventReClick
          :disabled="!(advertisement.subject && advertisement.url && advertisement.img_url)"
          @click="saveAdviseHandle"
          round
        >
          确定
        </vh-button>
      </span>
    </VhallDialog>
    <VhallDialog
      title="选择广告"
      :visible.sync="dialogAdverVisible"
      :close-on-click-modal="false"
      :before-close="handleClose"
      style="overflow: hidden"
      custom-class="choose-gift"
      width="660px"
    >
      <div class="content">
        <div class="search" v-show="total || isSearch">
          <vh-input
            round
            class="search-dialog-tag"
            v-model.trim="advertisementTitle"
            placeholder="请输入广告标题"
            style="width: 220px"
            @keyup.enter.native="changeAdverment"
            maxlength="50"
            @clear="changeAdverment"
            :clearable="true"
          >
            <i slot="prefix" class="vh-icon-search vh-input__icon" @click="changeAdverment"></i>
          </vh-input>
        </div>
        <div class="list" v-show="total" style="height: 315px">
          <vh-scrollbar v-loadMore="moreLoadData" style="height: 100%">
            <div class="ad-list">
              <div
                class="ad-item"
                v-for="(item, index) in adList"
                :key="index"
                :class="item.isChecked ? 'active' : ''"
                @click="choiseAdvisetion(item)"
              >
                <span class="spanImg">
                  <img
                    :class="`img_box_bg box_bg_${item.itemMode}`"
                    :src="`${item.img_url}`"
                    alt=""
                  />
                </span>
                <p>{{ item.subject }}</p>
                <label class="img-tangle" v-show="item.isChecked">
                  <img src="../../../../common/images/icon-choose.png" alt="" />
                </label>
                <!-- <label class="img-tangle" v-show="item.isChecked">
                <i class="vh-icon-check"></i>
              </label> -->
              </div>
            </div>
          </vh-scrollbar>
        </div>
      </div>
      <div class="no-live" :class="{ search: isSearch }" v-show="showNullData">
        <noData :nullType="nullText" :text="text" :height="0">
          <vh-button
            type="primary"
            v-if="nullText == 'nullData'"
            round
            @click="$router.push({ path: '/interactive/advertCard' })"
            v-preventReClick
          >
            创建广告
          </vh-button>
        </noData>
      </div>
      <p class="text" v-show="total || isSearch">
        当前选中
        <span>{{ selectChecked.length }}</span>
        个广告
      </p>
      <span slot="footer" class="dialog-footer sureBtn" v-show="total || isSearch">
        <vh-button type="info" @click="dialogAdverVisible = false" plain round size="medium">
          取消
        </vh-button>
        <vh-button
          type="primary"
          size="medium"
          @click="advSaveToWebinar()"
          :disabled="!selectChecked.length"
          v-preventReClick
          round
        >
          确定
        </vh-button>
      </span>
    </VhallDialog>
    <VhallDialog
      title="提示"
      :visible.sync="dialogTongVisible"
      :close-on-click-modal="false"
      :show-close="false"
      class="zdy-async-dialog"
      width="400px"
    >
      <div class="async__body">
        <div class="async__ctx">
          <p>保存广告同时共享至资料管理，便于其他活动使用？</p>
          <vh-checkbox v-model="sureChecked">共享到资料管理</vh-checkbox>
        </div>
        <div class="async__footer">
          <vh-button type="info" size="medium" @click="cancelMaterialAdver" plain round>
            取消
          </vh-button>
          <vh-button type="primary" size="medium" v-preventReClick @click="sureMaterialAdver" round>
            确定
          </vh-button>
        </div>
      </div>
    </VhallDialog>
    <!-- 裁剪组件 -->
    <cropper
      ref="adviseCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="400 / 225"
    ></cropper>
  </div>
</template>
<script>
  import upload from '@/components/Upload/main';
  import Env from '@/api/env';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import cropper from '@/components/Cropper/index';
  import {
    buildOssUploadProcessData,
    parseQueryString,
    buildUrlByMode
  } from '@/utils/utils';
  export default {
    data() {
      const linkValidate = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入广告链接'));
        } else {
          if (!this.linkCodeMatch(value)) {
            callback && callback('请输入以http://或https://开头的广告链接');
          } else {
            callback();
          }
        }
      };
      const nameValidate = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入广告标题'));
        } else {
          if (value.length > 15) {
            callback && callback('广告标题在15个字符以内');
          } else {
            callback();
          }
        }
      };
      return {
        dialogVisible: false,
        dialogAdverVisible: false,
        dialogTongVisible: false,
        advertisementTitle: '',
        selectChecked: [],
        total: 0,
        sureChecked: true,
        nullText: 'nullData',
        isSearch: false, //是否是搜索
        text: '您还没有广告，快来创建吧！',
        rules: {
          subject: [{ required: true, validator: nameValidate, trigger: 'blur' }],
          img_url: [{ required: true, message: '请选择推广图片', trigger: 'change' }],
          url: [{ required: true, validator: linkValidate, trigger: 'blur' }]
        },
        advertisement: {
          is_sync: 0,
          img_url: '',
          url: '',
          subject: null
        },
        advertPageInfo: {
          pos: 0,
          limit: 6,
          page: 1
        },
        maxPage: 0,
        adList: [],
        showNullData: false, //是否显示无数据场景提示
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1 //当前的正在使用的裁切模式
      };
    },
    props: {
      advInfo: {
        type: Object
      },
      maxTotal: {
        type: Number
      }
    },
    computed: {
      domain_url() {
        if (!this.advertisement.img_url) return '';
        return buildUrlByMode(this.advertisement.img_url, this.currentMode);
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'webinars/spread-imgs',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    components: {
      upload,
      noData,
      cropper
    },
    watch: {
      dialogVisible() {
        if (this.dialogVisible) {
          this.editShow();
        } else {
          this.advertisement = {};
          this.clearForm();
          this.$refs['advertisementForm'].resetFields();
        }
      },
      dialogAdverVisible() {
        if (this.dialogAdverVisible) {
          this.advertPageInfo = {
            pos: 0,
            limit: 6,
            page: 1
          };
          this.selectChecked = [];
          this.activityData();
        } else {
          this.adList = [];
          this.selectChecked = [];
          this.advertisementTitle = '';
        }
      },
      dialogTongVisible() {
        if (this.dialogTongVisible) {
          this.sureChecked = true;
        }
      }
    },
    methods: {
      handleClose(done) {
        this.advertPageInfo.page = 1;
        done();
      },
      editShow() {
        if (this.advInfo.adv_id) {
          this.$nextTick(() => {
            this.currentMode = this.advInfo.itemMode;
            this.$set(this.advertisement, 'img_url', this.advInfo.img_url);
            this.$set(this.advertisement, 'subject', this.advInfo.subject);
            this.$set(this.advertisement, 'url', this.advInfo.url);
            this.$set(this.advertisement, 'adv_id', this.advInfo.adv_id);
          });
        } else {
          this.clearForm();
        }
      },
      clearForm() {
        this.$set(this.advertisement, 'img_url', '');
        this.$set(this.advertisement, 'subject', '');
        this.$set(this.advertisement, 'url', '');
        this.$set(this.advertisement, 'adv_id', '');
      },
      // 验证链接
      linkCodeMatch(value) {
        let reg = /(http|https):\/\/[\w\-_]+(\.[\w\-_]+).*?/g;
        if (!reg.test(value)) {
          return false;
        } else {
          return true;
        }
      },
      saveAdviseHandle() {
        this.$refs.advertisementForm.validate(valid => {
          if (valid) {
            if (this.$route.params.str) {
              // 直播-新建广告
              if (this.advInfo.adv_id) {
                this.createAdvAndsync(0);
                this.$vhall_paas_port({
                  k: 100281,
                  data: {
                    business_uid: this.$parent.userId,
                    user_id: '',
                    webinar_id: this.$route.params.str,
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
              } else {
                this.dialogTongVisible = true;
              }
            } else {
              // 资料库中广告推荐，默认不同步到资料库
              // this.advertisement.is_sync = 0;
              this.createAdvAndsync(0);
            }
          }
        });
      },
      sureMaterialAdver() {
        if (this.sureChecked) {
          // 同步资料库
          // this.advertisement.is_sync = 1;
          this.createAdvAndsync(1);
          this.$vhall_paas_port({
            k: 100286,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        } else {
          // 不同步资料库
          // this.advertisement.is_sync = 0;
          this.createAdvAndsync(0);
          this.$vhall_paas_port({
            k: 100287,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
      },
      cancelMaterialAdver() {
        this.createAdvAndsync(0);
        this.$vhall_paas_port({
          k: 100287,
          data: {
            business_uid: this.$parent.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      createAdv() {
        this.$vhConfirm('是否同步到资料库?', '提示', {
          confirmButtonText: '同步',
          cancelButtonText: '不同步',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.advertisement.is_sync = 1;
            this.createAdvAndsync(1);
          })
          .catch(() => {});
      },
      createAdvAndsync(type) {
        let url = this.advertisement.adv_id ? 'updateAdv' : 'createAdv';
        this.advertisement.is_sync = type;
        if (this.$route.params.str) {
          this.advertisement.webinar_id = this.$route.params.str;
        }
        let params = Object.assign({}, this.advertisement);
        params.img_url = this.$parseURL(this.domain_url).path;
        this.$fetch(url, params)
          .then(res => {
            if (res && res.code === 200) {
              if (this.$route.params.str) {
                this.$vhall_paas_port({
                  k: this.advertisement.adv_id ? 100281 : 100280,
                  data: {
                    business_uid: this.$parent.userId,
                    user_id: '',
                    webinar_id: this.$route.params.str,
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
                if (type === 1 && !this.advertisement.adv_id) {
                  this.$vhall_paas_port({
                    k: 100551,
                    data: {
                      business_uid: this.$parent.userId,
                      user_id: '',
                      webinar_id: '',
                      refer: '',
                      s: '',
                      report_extra: {},
                      ref_url: '',
                      req_url: ''
                    }
                  });
                }
              } else {
                this.$vhall_paas_port({
                  k: this.advertisement.adv_id ? 100552 : 100551,
                  data: {
                    business_uid: this.$parent.userId,
                    user_id: '',
                    webinar_id: '',
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
              }
              this.dialogVisible = false;
              this.dialogTongVisible = false;
              this.advertisement = {};
              this.clearForm();
              this.$vhMessage({
                message: `${this.advInfo.adv_id ? '修改' : '创建'}成功`,
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              // 获取列表数据
              this.$emit('reload');
            } else {
              this.dialogVisible = true;
              this.$vhMessage({
                message: `链接格式不正确`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            }
          })
          .catch(res => {
            this.dialogVisible = true;
            this.$vhMessage({
              message: res.msg || `${this.advInfo.adv_id ? '修改' : '创建'}失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      moreLoadData() {
        if (this.advertPageInfo.page >= this.maxPage) {
          return false;
        }
        this.advertPageInfo.page++;
        this.advertPageInfo.pos = parseInt(
          (this.advertPageInfo.page - 1) * this.advertPageInfo.limit
        );
        this.activityData();
      },
      activityData() {
        this.showNullData = false;
        if (this.advertisementTitle) {
          this.nullText = 'search';
          this.text = '';
          this.isSearch = true;
        } else {
          this.nullText = 'nullData';
          this.text = '您还没有广告，快来创建吧！';
          this.isSearch = false;
        }
        this.$fetch(
          'getAdvList',
          this.$params({
            keyword: this.advertisementTitle,
            ...this.advertPageInfo
          })
        ).then(res => {
          if (res && res.code === 200) {
            let adList = res.data.adv_list;
            this.total = res.data.total;
            adList.map(item => {
              item.isChecked = false;
              let urlParams = parseQueryString(item.img_url);
              if (urlParams.mode) {
                item.itemMode = urlParams.mode;
              } else {
                item.itemMode = 3;
              }
            });
            this.adList.push(...adList);
            this.maxPage = Math.ceil(res.data.total / this.advertPageInfo.limit);

            // 是否显示无数据提示
            if (this.total == 0) {
              this.showNullData = true;
            }
          } else {
            this.adList = [];
          }
        });
      },
      changeAdverment() {
        this.advertPageInfo = {
          pos: 0,
          limit: 6,
          page: 1
        };
        this.adList = [];
        this.selectChecked = [];
        this.activityData();
      },
      choiseAdvisetion(items) {
        items.isChecked = !items.isChecked;
        this.selectChecked = this.adList.filter(item => item.isChecked).map(item => item.adv_id);
      },
      // 从资料库保存到活动
      advSaveToWebinar(id) {
        if (this.maxTotal + this.selectChecked.length > 50) {
          this.$vhMessage({
            message: `广告推荐个数已达到最大个数限制，请删除后再进行添加`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        let params = {
          webinar_id: this.$route.params.str,
          adv_ids: this.selectChecked.join(',')
        };
        this.$fetch('advSaveToWebinar', params).then(res => {
          if (res.code == 200) {
            this.$vhall_paas_port({
              k: 100283,
              data: {
                business_uid: this.$parent.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `选择广告成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.dialogAdverVisible = false;
            this.selectChecked = [];
            this.adList.map(item => {
              item.isChecked = false;
            });
            this.$emit('reload');
          } else {
            this.$vhMessage({
              message: `选择广告失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          }
        });
      },
      // 删除
      deleteImg() {
        this.advertisement.img_url = '';
      },
      resetChooseFile() {
        this.$refs.advise_uploader.resetChooseFile();
      },
      cropComplete(cropperData, url, mode) {
        this.currentMode = mode;
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          this.$refs.advise_uploader.upload();
        });
      },
      uploadAdvSuccess(res, file) {
        if (res.data) {
          this.advertisement.img_url = res.data.domain_url;
          // 触发验证
          this.$refs.advertisementForm.validateField('img_url');
        }
      },
      handleUploadChange(file, fileList) {
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 2, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.adviseCropper.showModel(e.target.result);
            };
          });
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .dialog-box {
    // .search-dialog-tag {

    // }
    ::v-deep .choose-gift {
      .head-btn.vh-input {
        width: 220px;
        margin-left: 32px;
      }
      .vh-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }

  .content {
    position: relative;
    .search {
      height: 40px;
      margin-bottom: 18px;
      padding: 0 32px;
    }
    .ad-list {
      display: flex;
      margin: 0 32px;
      //  justify-content: space-between;
      //  align-items: center;
      flex-wrap: wrap;
      //  overflow: auto;
      .ad-item {
        width: 165px;
        margin-bottom: 11px;
        background: #f7f7f7;
        position: relative;
        margin-right: 15px;
        height: 150px;
        border: 1px solid transparent;
        transition: all 0.15s ease-in;
        // background-size: 100% 100%;
        // animation: gradientBG 15s ease infinite;
        // padding: 10px 10px;
        // box-sizing: border-box;
        // position: relative;
        border-radius: 4px;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
        }
        &.active {
          background: #ffffff;
          box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
          border: 1px solid #fb3a32;
        }
        .img-tangle {
          position: absolute;
          right: -1px;
          top: -1px;
          width: 20px;
          height: 20px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .spanImg {
          display: block;
          width: 163px;
          height: 93px;
          background: #1a1a1a;
          border-radius: 4px 4px 0 0;
          .img_box_bg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            &.box_bg_1 {
              object-fit: fill;
            }
            &.box_bg_2 {
              object-fit: cover;
              object-position: left top;
            }
          }
        }
        p {
          padding: 5px 0 0 5px;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400px;
        }
      }
    }
  }
  .no-live {
    padding-top: 40px;
    padding-bottom: 10px;
    &.search {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  .sureQuestion {
    padding-bottom: 24px;
    .textPrize {
      padding-left: 50px;
      p {
        font-size: 16px;
        color: #1a1a1a;
        padding-bottom: 15px;
      }
    }
    .dialog-footer {
      text-align: center;
      margin-top: 20px;
    }
  }
  .text {
    margin-top: 10px;
    position: absolute;
    bottom: 40px;
    padding-left: 32px;
    span {
      color: #fb3a32;
      padding: 0 5px;
    }
  }
  .sureBtn {
  }
</style>
