<template>
  <div class="advertisement-card">
    <div class="title-data">
      <span>广告</span>
    </div>
    <div class="advertisement-main">
      <div class="search-data">
        <vh-button size="medium" class="length104" type="primary" @click="createAdvise()" round>
          创建广告
        </vh-button>
        <vh-button
          size="white-medium"
          class="head-btn length104 transparent-btn"
          round
          @click="createCenter()"
          v-if="$route.path != '/interactive/advertCard'"
        >
          资料库
        </vh-button>
        <vh-button
          size="medium"
          class="head-btn length104 transparent-btn"
          round
          @click="allDelete(null)"
          :disabled="!adv_ids.length"
        >
          批量删除
        </vh-button>
        <vh-input
          round
          class="search-tag"
          placeholder="请输入广告标题"
          v-model="paramsObj.keyword"
          clearable
          @clear="searchAdvTableList"
          @keyup.enter.native="searchAdvTableList"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchAdvTableList"></i>
        </vh-input>
      </div>
      <!-- <div class="no-live" v-if="total && !isSearch">
        <noData :nullType="'nullData'" :text="'您还没有广告，快来创建吧！'">
          <vh-button type="primary" round @click="createAdvise()" v-preventReClick>
            创建广告
          </vh-button>
          <vh-button
            type="white-primary"
            round
            plain
            v-if="$route.path != '/interactive/advertCard'"
            @click="createCenter()"
            v-preventReClick
          >
            资料库
          </vh-button>
        </noData>
      </div> -->
      <div class="advert-card-list" v-loading="loadingData">
        <table-list
          v-show="total"
          ref="tableList"
          :manageTableData="tableList"
          :tabelColumnLabel="tabelColumn"
          :tableRowBtnFun="tableRowBtnFun"
          :totalNum="total"
          :width="120"
          @onHandleBtnClick="onHandleBtnClick"
          @getTableList="getAdvTableList"
          @changeTableCheckbox="changeTableCheckbox"
        ></table-list>
        <noData :nullType="'search'" v-if="!total && isSearch"></noData>
        <noData
          :nullType="'nullData'"
          :text="'您还没有广告，快去创建吧！'"
          v-if="showNullData"
        ></noData>
      </div>
      <create-advise
        ref="adviseSonChild"
        :advInfo="advInfo"
        @reload="getAdvTableList"
        :maxTotal="total"
      ></create-advise>
    </div>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4 && $route.path != '/interactive/advertCard'"
    ></begin-play>
  </div>
</template>
<script>
  import createAdvise from './components/createAdvise';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal, parseQueryString } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  export default {
    data() {
      return {
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        advInfo: {},
        adv_ids: [],
        paramsObj: {
          keyword: ''
        },
        pos: 0,
        limit: 10,
        total: 0,
        isSearch: false, //是否是搜索
        tableList: [],
        tabelColumn: [
          {
            label: '图片',
            key: 'img_url',
            width: 170
          },
          {
            label: '标题',
            key: 'subject'
          },
          {
            label: '链接',
            key: 'url',
            customTooltip: true
          },
          {
            label: '创建时间',
            key: 'created_at',
            width: 170
          }
        ],
        tableRowBtnFun: [
          {
            name: '编辑',
            methodName: 'edit'
          },
          {
            name: '删除',
            methodName: 'delete'
          }
        ],
        selectedTableItem: [],
        loadingData: false, //默认加载数据中
        showNullData: false //是否显示无数据提示
      };
    },
    components: {
      createAdvise,
      noData,
      beginPlay
    },
    created() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
    },
    mounted() {
      this.getAdvTableList();
    },
    methods: {
      searchAdvTableList() {
        if (this.paramsObj.keyword) {
          this.$vhall_paas_port({
            k: this.$route.params.str ? 100288 : 100555,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str || '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getAdvTableList('search');
      },
      getAdvTableList(param) {
        this.loadingData = true;
        let pageInfo = this.$refs.tableList.pageInfo; //获取分页信息
        if (param == 'search') {
          pageInfo.pos = 0;
          pageInfo.pageNum = 1;
        }
        let params = {
          keyword: this.paramsObj.keyword,
          pos: pageInfo.pos,
          limit: pageInfo.limit,
          webinar_id: this.$route.params.str || ''
        };
        this.isSearch = this.paramsObj.keyword ? true : false;
        this.$fetch('getAdvList', this.$params(params)).then(res => {
          this.loadingData = false;
          this.total = res.data.total;
          let list = res.data.adv_list;
          list.map(item => {
            let urlParams = parseQueryString(item.img_url);
              if (urlParams.mode) {
                item.itemMode = urlParams.mode;
              } else {
                item.itemMode = 3;
              }
          });
          this.tableList = list;
          if (this.total == 0 && this.isSearch == false) {
            this.showNullData = true;
          } else {
            this.showNullData = false;
          }
        });
      },
      onHandleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      edit(that, { rows }) {
        that.advInfo = rows;
        console.log(that.advInfo, '?????????????');
        that.$refs.adviseSonChild.dialogVisible = true;
      },
      delete(that, { rows }) {
        that.deleteConfirm(rows.adv_id, 2);
      },
      deleteConfirm(id, index) {
        this.$vhConfirm('是否删除当前广告？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch(
            'deleteAdv',
            this.$params({
              adv_ids: id,
              webinar_id: this.$route.params.str
            })
          )
            .then(res => {
              if (res && res.code === 200) {
                let k = 0;
                if (this.$route.params.str) {
                  k = index == 1 ? 100285 : 100284;
                } else {
                  k = index == 1 ? 100554 : 100553;
                }
                this.$vhall_paas_port({
                  k: k,
                  data: {
                    business_uid: this.userId,
                    user_id: '',
                    webinar_id: this.$route.params.str || '',
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 刷新页面
                this.$refs.tableList.clearSelect();
                this.getAdvTableList('search');
                this.adv_ids = [];
              } else {
                this.$vhMessage({
                  message: res.msg || `删除失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || `删除失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        });
      },
      allDelete(id) {
        id = this.adv_ids.join(',');
        this.deleteConfirm(id, 1);
      },
      changeTableCheckbox(val) {
        console.log(val);
        this.adv_ids = val.map(item => item.adv_id);
      },
      createAdvise(title) {
        if (this.$route.path != '/interactive/advertCard' && this.total >= 50) {
          this.$vhMessage({
            message: `广告推荐个数已达到最大个数限制，请删除后再进行添加`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        this.advInfo = {};
        this.$refs.adviseSonChild.dialogVisible = true;
      },
      createCenter() {
        this.$refs.adviseSonChild.dialogAdverVisible = true;
        // this.$refs.adviseSonChild.activityData();
      }
    }
  };
</script>
<style lang="less" scoped>
  .advert-card-list {
    .layout--right--main();
    .padding-table-list();
    .min-height();
  }
  .advertisement-card {
    .title-data {
      margin: 0 0 20px 0;
      text-align: left;
      line-height: 30px;
      span {
        font-size: 22px;
        font-weight: 600;
        color: #1a1a1a;
      }
      .button-tip {
        vertical-align: top;
      }
    }
    .advertisement-main {
      .vh-card__body {
        padding: 32px 24px;
      }
      .search-data {
        margin-bottom: 30px;
      }

      .search-tag {
        float: right;
        width: 220px;
      }
    }
  }
  // .no-live {
  //   .btn-list .vh-button {
  //     margin-right: 0;
  //   }
  // }
</style>
